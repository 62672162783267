import React, { createContext, FC, useCallback, useContext, useState } from 'react';

export const MessageLevel = {
  success: 'success',
  error: 'error',
} as const;

type MessageLevel = keyof typeof MessageLevel;

export interface FlashMessageType {
  message: string;
  level: MessageLevel;
  /**
   * @deprecated 管理画面と閲覧画面でフラッシュメッセージの差分がなくなったので不要になる
   */
  usedInAdmin: boolean;
}

interface FlashMessageContextType {
  flashMessage: FlashMessageType;
  sendFlashMessage: (message: FlashMessageType) => void;
}

const FlashMessageContext = createContext<FlashMessageContextType>({
  flashMessage: {
    message: '',
    level: MessageLevel.success,
    usedInAdmin: true,
  },
  sendFlashMessage: () => {},
});

export const FlashMessageProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [flashMessage, setFlashMessage] = useState<FlashMessageType>({
    message: '',
    level: MessageLevel.success,
    usedInAdmin: true,
  });
  const sendFlashMessage = useCallback(
    (flashMessage: FlashMessageType) => {
      setFlashMessage(flashMessage);
    },
    [setFlashMessage],
  );

  return (
    <FlashMessageContext.Provider value={{ flashMessage, sendFlashMessage }}>{children}</FlashMessageContext.Provider>
  );
};

export const useFlashMessage = () => {
  const { flashMessage, sendFlashMessage } = useContext(FlashMessageContext);
  return { flashMessage, sendFlashMessage };
};
