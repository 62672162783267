import Router, { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { memo, useEffect, useRef } from 'react';
import { useMicrosoftAuth } from '~/components/functional/MicrosoftAuthProvider';
import { ErrorComponent } from '~/components/page/Error';
import { isHavingRequestErrorPayload } from '~/external/api/client/RequestError';
import { authStatePersistence } from '~/external/authStatePersistence';
import { useLogin } from '~/hooks/domain/session/useLogin';
import { isForbidden } from '~/utils/auth/Forbidden';
import { useFlashMessage } from '../FlashMessageProvider';
import { HeadTemplate } from '../Head';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSameError = (prevError: any, nextError: any) => {
  if (isHavingRequestErrorPayload(prevError) && isHavingRequestErrorPayload(nextError)) {
    return prevError.status === nextError.status && prevError.errorMessage === nextError.errorMessage;
  }
  return prevError === nextError;
};

interface Props {
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  isSSRError?: boolean;
}

const ErrorPageHandlerComponent: React.FC<Props> = ({ children, error, isSSRError = false }) => {
  const { t } = useTranslation();
  const { sendFlashMessage } = useFlashMessage();
  const router = useRouter();
  const isAdmin = Boolean(router.asPath.match('admin'));
  const { signOut } = useMicrosoftAuth();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prevErrorRef = useRef<any>(null);
  const { redirectToLogin } = useLogin();

  useEffect(() => {
    if (isSameError(prevErrorRef.current, error)) return;
    if (isHavingRequestErrorPayload(error) && error.errorMessage) {
      // 400, 409, 422の時はフラッシュメッセージを表示する
      if (error.status === 400 || error.status === 409 || error.status === 422) {
        sendFlashMessage({ message: error.errorMessage, level: 'error', usedInAdmin: isAdmin });
      }
    }
  }, [error, isAdmin, sendFlashMessage]);

  useEffect(() => {
    const redirect = async () => {
      if (error.status === 401) {
        authStatePersistence.removeAll({ microsoftSignOut: signOut });

        await redirectToLogin(Router.asPath);
      }
    };
    redirect();
  }, [error.status, redirectToLogin, router, signOut]);

  if (isForbidden(error)) {
    return (
      <>
        <HeadTemplate pageTitle={t(`error:403.title`)} />
        <ErrorComponent errorCode={403} errorTitle={t(`error:403.title`)} errorMessage={t(`error:403.message`)} />
      </>
    );
  }

  if (isHavingRequestErrorPayload(error)) {
    switch (error.status) {
      case 403:
      case 404:
      case 500:
        return (
          <>
            <HeadTemplate pageTitle={t(`error:${error.status}.title`)} />
            <ErrorComponent
              errorCode={error.status}
              errorTitle={t(`error:${error.status}.title`)}
              errorMessage={t(`error:${error.status}.message`)}
            />
          </>
        );

      case 401: // 401の場合はuseEffectで非同期的に処理をしたいため、リダイレクトが完了するまで画面を維持させる
      case 400:
        // 起こらないと思うがサーバーサイドで400エラーがあった時は汎用エラーを表示する
        if (!isSSRError) {
          return <>{children}</>;
        }
      case 409:
      case 422:
      default:
        return (
          <>
            <HeadTemplate pageTitle={t('error:4XX.title')} />
            <ErrorComponent />
          </>
        );
    }
  }
  return (
    <>
      <HeadTemplate pageTitle={t('error:4XX.title')} />
      <ErrorComponent />
    </>
  );
};

export const ErrorPageHandler = memo(ErrorPageHandlerComponent, (prevProps, nextProps) => {
  return isSameError(prevProps.error, nextProps.error) && prevProps.children === nextProps.children;
});
ErrorPageHandler.displayName = 'ErrorPageHandler';
