import { useEffect, useMemo, useState } from 'react';

export const usePWA = () => {
  const [isPWA, setIsPWA] = useState<boolean>();

  useEffect(() => {
    // @see https://developer.mozilla.org/ja/docs/Web/CSS/@media/display-mode
    const displayModeQuery = window.matchMedia('(display-mode: standalone)');
    setIsPWA(displayModeQuery.matches);

    const onChangeDisplayMode = (event: MediaQueryListEvent) => {
      setIsPWA(event.matches);
    };
    displayModeQuery.addEventListener('change', onChangeDisplayMode);

    return () => {
      displayModeQuery.removeEventListener('change', onChangeDisplayMode);
    };
  }, []);

  const [enableNotification, setEnableNotification] = useState<boolean>();
  const [enableServiceWorker, setEnableServiceWorker] = useState<boolean>();

  useEffect(() => {
    setEnableNotification('Notification' in window);
    setEnableServiceWorker('serviceWorker' in navigator);
  }, []);

  const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState<ServiceWorkerRegistration>();

  useEffect(() => {
    if (!enableServiceWorker) {
      return;
    }
    navigator.serviceWorker.ready.then(registration => {
      setServiceWorkerRegistration(registration);
    });
  }, [enableServiceWorker]);

  const isReadyServiceWorker = useMemo(() => {
    if (enableServiceWorker == null) {
      return false;
    }
    // service workerが使用不可な環境の場合、setup完了とみなす
    if (!enableServiceWorker) {
      return true;
    }
    return !!serviceWorkerRegistration;
  }, [enableServiceWorker, serviceWorkerRegistration]);

  const isReady = useMemo(
    () => isPWA != null && enableNotification != null && isReadyServiceWorker,
    [enableNotification, isPWA, isReadyServiceWorker],
  );

  return {
    isReady,
    isPWA,
    enableNotification,
    enableServiceWorker,
  };
};
