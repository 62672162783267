/**
 * Video BRAINサポートの組織名
 * --------------------------------------------
 * - Open BRAINと契約していなくても閲覧ができる特殊なポータルを所有する組織
 */
const VB_SUPPORT_ORGANIZATION_NAME = 'open8' as const;

/**
 * Video BRAINサポートのポータル名（日本語版）
 * --------------------------------------------
 * - Open BRAINと契約していなくても閲覧ができる特殊なポータル
 */
const VB_SUPPORT_PORTAL_NAME = 'vbsupport' as const;
/**
 * Video BRAINサポートのポータル名（英語版）
 * --------------------------------------------
 * - Open BRAINと契約していなくても閲覧ができる特殊なポータル
 */
const VB_SUPPORT_ENGLISH_PORTAL_NAME = 'vbsupport-en' as const;

/**
 * Video BRAINサポートポータルか? を返します.
 *
 * @param organization 組織名
 * @param portal ポータル名
 */
function isVideoBrainSupport(organization?: string | string[], portal?: string | string[]) {
  if (typeof organization !== 'string' || typeof portal !== 'string') {
    return false;
  }
  return organization === VB_SUPPORT_ORGANIZATION_NAME && isSupportPortal(portal);
}

/**
 * ユーザの言語をもとに、Video Brainサポートポータルの名称を返します.
 *
 * @param language 言語
 */
function findVideoBrainSupportPortalBy(language: string) {
  switch (language) {
    case 'ja':
      return VB_SUPPORT_PORTAL_NAME;
    case 'en':
      return VB_SUPPORT_ENGLISH_PORTAL_NAME;
    default:
      return VB_SUPPORT_PORTAL_NAME;
  }
}

function isSupportPortal(portal: string) {
  return portal === VB_SUPPORT_PORTAL_NAME || portal === VB_SUPPORT_ENGLISH_PORTAL_NAME;
}

export { isVideoBrainSupport, findVideoBrainSupportPortalBy };
