import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { isVideoBrainSupport } from '~/constants/VideoBrainSupport';

export const useLogin = () => {
  const router = useRouter();

  const redirectToLogin = useCallback(
    async (returnTo?: string) => {
      // NOTE: 以下目的のために分岐を設けている
      // - 無駄なredirect処理を行わない
      // - session timeout等で複数APIで同時多発的に認証エラーが発生した場合に ErrorBoundaryを経由して当redirect処理が複数回呼ばれる
      //   これが原因で画面遷移が複数回発生し、意図しない挙動になることを防ぐ (e.g. [認証エラー] => vb-login => login => login => ...)
      if (router.pathname === '/login' || router.pathname === '/vb-login') {
        return;
      }
      // Open BRAINと契約していなくても閲覧ができる特殊なポータル(vbsupport)の場合
      // 通常のOpen BRAINのログインではなく、Video BRAINアカウントでログインする専用ページに飛ばす
      const pathname = isVideoBrainSupport(router.query?.organization, router.query?.portal) ? '/vb-login' : '/login';
      const query = returnTo ? { redirectUri: returnTo } : undefined;

      await router.push({ pathname, query });
    },
    [router],
  );

  return {
    redirectToLogin,
  };
};
