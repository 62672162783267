import { LOCAL_STORAGE_KEY } from '~/constants';
import { BoxAuthClient } from './Box/BoxAuthClient';
import { GoogleAuthClient } from './google/GoogleAuthClient';

export const authStatePersistence = {
  microsoft: {
    getAccountSelectionDone: () => {
      return localStorage?.getItem(LOCAL_STORAGE_KEY.MS_ACCOUNT_SELECTION_DONE) === 'true';
    },
    setAccountSelectionDone: (done: boolean) => {
      if (done) localStorage?.setItem(LOCAL_STORAGE_KEY.MS_ACCOUNT_SELECTION_DONE, 'true');
      else localStorage?.removeItem(LOCAL_STORAGE_KEY.MS_ACCOUNT_SELECTION_DONE);
    },
  },
  removeAll: async ({ microsoftSignOut }: { microsoftSignOut: () => void }) => {
    localStorage?.removeItem(LOCAL_STORAGE_KEY.MS_ACCOUNT_SELECTION_DONE);
    await microsoftSignOut();
    await GoogleAuthClient.instance?.signOut();
    await BoxAuthClient.instance?.signOut();
  },
};
