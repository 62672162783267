export type Forbidden = {
  forbidden: boolean;
};

export const isForbidden = (error: any): boolean => {
  if (typeof error?.forbidden === 'boolean') {
    return error.forbidden;
  }
  return false;
};
