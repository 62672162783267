import { createContext, FC, useState, useCallback, useMemo, useContext } from 'react';

interface FavoriteCacheContextType {
  cache: {
    [articleId: number]: {
      favorite: boolean;
      favoritesCount: number;
    };
  };
  setFavoriteCache: (articleId: number, favorite: boolean, favoritesCount: number) => void;
}

const FavoriteCacheContext = createContext<FavoriteCacheContextType>({
  cache: {},
  setFavoriteCache: () => {},
});

export const FavoriteCacheProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cache, setCache] = useState({});

  const setFavoriteCache = useCallback(
    (articleId: number, favorite: boolean, favoritesCount: number) => {
      setCache(prev => ({
        ...prev,
        [articleId]: {
          favorite,
          favoritesCount,
        },
      }));
    },
    [setCache],
  );

  const value = useMemo(
    () => ({
      cache,
      setFavoriteCache,
    }),
    [cache, setFavoriteCache],
  );

  return <FavoriteCacheContext.Provider value={value}>{children}</FavoriteCacheContext.Provider>;
};

export const useFavoriteCache = () => {
  return useContext(FavoriteCacheContext);
};
