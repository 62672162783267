import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { useCallback } from 'react';
import { OrganizationService } from '~/external/api/organizationService';
import { useLogin } from '~/hooks/domain/session';

export interface Props {
  errorCode?: number;
  errorTitle?: string;
  errorMessage?: string;
}

export const ErrorComponent: React.FC<Props> = ({ errorCode, errorTitle, errorMessage }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { redirectToLogin } = useLogin();

  const handleClickTopLink = useCallback(async () => {
    try {
      const { name: organizationName } = await OrganizationService().GetOrganization({});
      router.push(`/${organizationName}`);
      return;
    } catch {
      // NOTE: 未ログインなどエラー時は別途エラー画面に飛ばす必要がないので何もしない
    }

    redirectToLogin();
  }, [redirectToLogin, router]);

  return (
    <div className="flex justify-center items-start p-8 w-full min-h-screen bg-accent-50 sm:items-center">
      <div className="flex flex-col gap-6 justify-center items-center p-6 bg-white rounded-lg sm:gap-14 sm:p-14">
        <div className="flex flex-col gap-6">
          {errorCode && errorTitle && (
            <div className="flex flex-col items-center font-[Helvetica]">
              <div className="text-[36px] font-bold leading-[160%]">{errorCode}</div>
              <div className="text-[24px] font-bold leading-[160%]">{errorTitle}</div>
            </div>
          )}

          <div className="text-center whitespace-pre-wrap title-12 sm:title-14">
            {errorMessage ?? t('error:4XX.message')}
          </div>
        </div>
        <button
          className="py-3 px-4 text-[12px] leading-[18px] bg-white hover:bg-accent-50 rounded-full border border-black-400 sm:py-2"
          onClick={handleClickTopLink}
        >
          {t('error:goToTop')}
        </button>
      </div>
    </div>
  );
};
