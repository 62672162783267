export class BoxLoginPopupHandler {
  static popupWindow: Window | null = null;
  static popupWindowWatcher: NodeJS.Timer | null = null;

  static popupLogin(authorizationUrl: string) {
    return new Promise<string>((resolve, reject) => {
      this.cleanUp();
      if (typeof window !== 'undefined') {
        this.popupWindow = window.open(authorizationUrl, '_blank', 'width=1024,height=768,top=200,left=200');
        this.popupWindowWatcher = setInterval(() => {
          if (this.popupWindow && this.popupWindow.closed) {
            this.cleanUp();
            // 認証中断時を別途ハンドリングできるようにカスタムエラーにしても良いかも
            reject(new Error('認証が中断されました'));
            return;
          }

          let popupHref = '';
          let popupQuery = '';
          // cross-originの時にエラーになるので握りつぶす
          // microsoftのポップアップを参考に実装
          // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/src/interaction_client/PopupClient.ts#L306-L321
          try {
            popupHref = this.popupWindow?.location.href ?? '';
            popupQuery = this.popupWindow?.location.search ?? '';
          } catch (error) {}
          const isAuthSucceeded = popupHref && popupHref !== 'about:blank';
          if (isAuthSucceeded) {
            const params = new URLSearchParams(popupQuery);
            this.cleanUp();
            const code = params.get('code');
            if (code) {
              resolve(code);
            } else {
              reject(new Error('認証が中断されました'));
            }
            return;
          }
        }, 100);
      }
    });
  }

  private static cleanUp() {
    this.cleanUpWatcher();
    this.cleanUpWindow();
  }

  private static cleanUpWatcher() {
    if (this.popupWindowWatcher) {
      clearInterval(this.popupWindowWatcher);
      this.popupWindowWatcher = null;
    }
  }

  private static cleanUpWindow() {
    if (this.popupWindow) {
      this.popupWindow.close();
      this.popupWindow = null;
    }
  }
}
