import { useEffect, useState } from 'react';
import { usePWA } from '~/hooks/usePWA';

export const useGoogleTagManager = () => {
  const [id, setId] = useState<string>();

  const { isReady, isPWA } = usePWA();

  useEffect(() => {
    if (!isReady) {
      return;
    }
    // NOTE: 分析する際に Browser/PWAを区別するためにタグを使い分ける
    const gtmId = isPWA ? process.env.NEXT_PUBLIC_PWA_GTM_ID : process.env.NEXT_PUBLIC_BROWSER_GTM_ID;
    setId(gtmId);
  }, [isPWA, isReady]);

  return { gtmId: id };
};
