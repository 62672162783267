const snakeToCamelStr = (str: string) => str.replace(/_./g, s => s.charAt(1).toUpperCase());
const camelToSnakeStr = (str: string) => str.replace(/([A-Z])/g, s => `_${s.charAt(0).toLowerCase()}`);

export const snakeToCamel = (obj: unknown): unknown => {
  if (Array.isArray(obj)) return obj.map(snakeToCamel);

  if (obj instanceof Object)
    return Object.entries(obj).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [snakeToCamelStr(key)]: snakeToCamel(value),
      }),
      {},
    );

  return obj;
};

export const camelToSnake = (obj: unknown): unknown => {
  if (Array.isArray(obj)) return obj.map(camelToSnake);

  if (obj instanceof Object)
    return Object.entries(obj).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [camelToSnakeStr(key)]: camelToSnake(value),
      }),
      {},
    );

  return obj;
};
