import React, { createContext, FC, useCallback, useContext, useState } from 'react';

export type ReadArticleIds = Set<number>;

interface ArticleReadContextType {
  readArticleIds: ReadArticleIds;
  updateArticleRead: (id: number) => void;
}

const ArticleReadContext = createContext<ArticleReadContextType>({
  readArticleIds: new Set<number>(),
  updateArticleRead: () => {},
});

export const ArticleReadProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [readArticleIds, setReadArticleIds] = useState<ReadArticleIds>(new Set<number>());
  const updateArticleRead = useCallback(
    (id: number) => {
      setReadArticleIds(readArticleIds.add(id));
    },
    [readArticleIds],
  );

  return (
    <ArticleReadContext.Provider value={{ readArticleIds, updateArticleRead }}>{children}</ArticleReadContext.Provider>
  );
};

export const useArticleRead = () => {
  const { readArticleIds, updateArticleRead } = useContext(ArticleReadContext);
  return { readArticleIds, updateArticleRead };
};
