/* eslint @typescript-eslint/no-explicit-any: 0 */

export interface PostSessionRequest {
  email: string;
  password: string;
  force?: boolean;
  serviceName: string;
}

export const PostSessionRequest = {
  fromJson: (object: any) => ({
    email: String(object?.email ?? ''),
    password: String(object?.password ?? ''),
    force: object?.force != null ? Boolean(object.force) : undefined,
    serviceName: String(object?.serviceName ?? ''),
  }),
};

interface Message {
  base: string;
}

const Message = {
  fromJSON: (object: any): Message => ({
    base: String(object?.base ?? ''),
  }),
};

interface ErrorType {
  code?: number;
  messages?: Message[];
}

const ErrorType = {
  fromJson: (object: any): ErrorType => ({
    code: object?.code != null ? Number(object.code) : undefined,
    messages: Array.isArray(object?.messages) ? object.messages.map(Message.fromJSON) : undefined,
  }),
};

interface User {
  id: number;
  identifierHash: string;
}

const User = {
  fromJson: (object: any): User => ({
    id: Number(object?.id ?? 0),
    identifierHash: String(object?.identifierHash ?? ''),
  }),
};

export interface PostSessionResponse {
  code: number;
  token: string;
  tokenExpireAt: number;
  createdAt: number;
  user: User;
  error?: ErrorType;
}

export const PostSessionResponse = {
  fromJson: (object: any): PostSessionResponse => {
    return {
      code: Number(object?.code ?? 0),
      token: String(object?.token ?? ''),
      tokenExpireAt: Number(object?.tokenExpireAt ?? 0),
      createdAt: Number(object?.createdAt ?? 0),
      user: User.fromJson(object?.user),
      error: object?.error != null ? ErrorType.fromJson(object.error) : undefined,
    };
  },
};
