import Script from 'next/script';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { LOCAL_STORAGE_KEY } from '~/constants';
import { RedirectOption } from '~/utils/domain/externalServiceRedirect/utils';
import {
  isAuthInfo,
  isInitCodeClientError,
  isInitCodeClientResponse,
  isRefreshTokenResponse,
  isTokenResponse,
} from './model';

const SCOPE = 'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.readonly';

const createRedirectOption = (nonce: string): RedirectOption | undefined => {
  const separateIndex = document.domain.indexOf('.');
  const [subDomain, domain] = [document.domain.slice(0, separateIndex), document.domain.slice(separateIndex)];

  const isPreviewDeployment = domain === '.vercel-front-development.video-b.com';
  return {
    o: subDomain,
    p: isPreviewDeployment,
    n: nonce,
  };
};

export class GoogleAuthClient {
  private static _instance: GoogleAuthClient | null = null;
  private _gsiLoaded = false;
  private _pickerApiLoaded = false;

  constructor() {}

  /**
   * シングルトンなインスタンス
   * windowがない環境では参照できない
   */
  static get instance() {
    if (!GoogleAuthClient._instance && typeof window !== 'undefined') {
      GoogleAuthClient._instance = new GoogleAuthClient();
    }
    return GoogleAuthClient._instance ?? null;
  }

  public async signIn(onSuccess?: (accessToken: string) => void) {
    const nonce = uuid();
    await fetch('/api/nonce', { method: 'PUT', body: nonce });
    const option = createRedirectOption(nonce);

    google.accounts.oauth2
      .initCodeClient({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? '',
        callback: async response => {
          if (!isInitCodeClientResponse(response)) {
            // アクセスがユーザーによって拒否されてサインインが中断された時はエラーにせずに、処理を終了する。
            if (isInitCodeClientError(response) && response.error === 'access_denied') return;
            // callbackはライブラリ内部で処理されるため、 throw すると try catch などで検知できずにエラーページにリダイレクトされてしまうのでconsoleにエラーを出力する。
            // 今後必要であれば、エラーハンドリングを追加する。
            // eslint-disable-next-line no-console
            console.error(response);
            return;
          }
          const tokenResponse = await this.fetchTokens(response.code);
          onSuccess?.(tokenResponse.access_token);
        },
        scope: SCOPE,
        ux_mode: 'popup',
        state: option ? JSON.stringify(option) : undefined,
      })
      .requestCode();
  }

  public async signOut() {
    if (!this._pickerApiLoaded || !this._gsiLoaded) return;
    const accessToken = await this.getAccessToken();
    if (!accessToken) return;
    const onSuccess = () => window.localStorage.removeItem(LOCAL_STORAGE_KEY.GOOGLE_AUTH_INFO_KEY);
    google.accounts.oauth2.revoke(accessToken, onSuccess);
  }

  private async fetchTokens(code: string) {
    try {
      const response = await fetch('/api/google-api-proxy/oauth2/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, redirectUri: window.location.origin }),
      });
      if (response.ok) {
        const responseJson = await response.json();
        if (!isTokenResponse(responseJson)) throw responseJson;
        this.setAccountInfo(responseJson.access_token, responseJson.refresh_token, responseJson.expires_in);
        return responseJson;
      }
      throw new Error('token fetch failed');
    } catch (e) {
      throw e;
    }
  }

  public async getAccessToken() {
    const authInfo = this.getAccountInfo();
    if (!authInfo) return null;
    if (authInfo.expiresAt < Date.now()) {
      const accessToken = await this.refreshToken(authInfo.refreshToken);
      return accessToken;
    }
    return authInfo.accessToken;
  }

  private async refreshToken(refreshToken: string) {
    try {
      const response = await fetch('/api/google-api-proxy/oauth2/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken, redirectUri: window.location.origin }),
      });
      if (response.ok) {
        const responseJson = await response.json();
        if (!isRefreshTokenResponse(responseJson)) return null;
        this.setAccountInfo(responseJson.access_token, refreshToken, responseJson.expires_in);
        return responseJson.access_token;
      }
      throw new Error('token refresh failed');
    } catch (e) {
      try {
        // refreshTokenが間違っている場合、サインインしなおして
        return await new Promise<string>(resolve => this.signIn(accessToken => resolve(accessToken)));
      } catch (e) {
        throw e;
      }
    }
  }

  private getAccountInfo() {
    const accountInfo = window.localStorage.getItem(LOCAL_STORAGE_KEY.GOOGLE_AUTH_INFO_KEY);
    if (!accountInfo) return null;
    const authInfoJson = JSON.parse(accountInfo);
    if (typeof authInfoJson !== 'object') return null;
    if (!isAuthInfo(authInfoJson)) return null;
    return authInfoJson;
  }

  private setAccountInfo(accessToken: string, refreshToken: string, expiresIn: number) {
    // リフレッシュの余裕を持たせるために数秒引いておく
    const authInfo = { accessToken, refreshToken, expiresAt: Date.now() + (expiresIn - 10) * 1000 };
    window.localStorage.setItem(LOCAL_STORAGE_KEY.GOOGLE_AUTH_INFO_KEY, JSON.stringify(authInfo));
  }

  public getAccountSelectionDone() {
    const authInfoJson = this.getAccountInfo();
    return !!authInfoJson;
  }

  public renderGsiLoader(onLoad: () => void) {
    return (
      <Script
        src="https://accounts.google.com/gsi/client"
        strategy="lazyOnload"
        onReady={() => {
          this._gsiLoaded = true;
          onLoad();
        }}
      />
    );
  }

  public renderApiLoader(onLoad: () => void) {
    return (
      <Script
        src="https://apis.google.com/js/api.js"
        strategy="lazyOnload"
        onReady={() => {
          if ('gapi' in window) {
            const { gapi } = window;
            gapi.load('picker', () => {
              this._pickerApiLoaded = true;
              onLoad();
            });
          }
        }}
      />
    );
  }
}
