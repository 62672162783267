
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '~/styles/globals.css';
import 'rc-calendar/assets/index.css';
import '~/styles/rc-calendar.css'; // このCSSで優先させるため、必ず rc-calendar/assets/index.css より後に読み込む
import 'rc-time-picker/assets/index.css';
import '~/styles/rc-time-picker.css'; // このCSSで優先させるため、必ず rc-time-picker/assets/index.css より後に読み込む
import '~/styles/markdown.css'; // マークダウン用のCSS
import { MsalProvider } from '@azure/msal-react';
import { GoogleTagManager } from '@next/third-parties/google';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { useCallback, useEffect, useState } from 'react';
import { AppPropsWithLayout } from 'types/app';
import { ArticleReadProvider } from '~/components/functional/ArticleReadProvider';
import { ErrorPageHandler } from '~/components/functional/ErrorPageHandler';
import { FavoriteCacheProvider } from '~/components/functional/FavoriteCacheProvider';
import { FlashMessage } from '~/components/functional/FlashMessage';
import { FlashMessageProvider } from '~/components/functional/FlashMessageProvider';
import { MicrosoftAuthProvider } from '~/components/functional/MicrosoftAuthProvider';
import { SubscribingAtMasterPortalProvider } from '~/components/functional/SubscribingAtMasterPortalProvider';
import { Loading } from '~/components/ui/Loading';
import { BoxAuthProvider } from '~/external/Box/BoxAuthProvider';
import { GoogleAuthProvider } from '~/external/google/GoogleAuthProvider';
import { msalInstance } from '~/external/microsoft/msalInstance';
import { useGoogleTagManager } from '~/hooks/useGoogleTagManager';

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { t } = useTranslation();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { gtmId } = useGoogleTagManager();

  // ブラウザバック、フォワードが実行された時にローディング画面を表示する
  useEffect(() => {
    const handlePopstate = () => {
      setLoading(true);
      return true;
    };

    const handleComplete = () => {
      setLoading(false);
    };

    router.beforePopState(handlePopstate);
    router.events.on('routeChangeComplete', handleComplete);

    return () => {
      router.events.off('routeChangeComplete', handleComplete);
    };
  }, [router]);

  const renderLoading = useCallback(() => {
    return (
      <div className="flex fixed inset-0 z-40 justify-center items-center w-screen h-screen bg-white">
        <Loading />
      </div>
    );
  }, []);

  if (pageProps.error !== undefined) {
    return (
      <FlashMessageProvider>
        <FlashMessage />
        <ErrorPageHandler error={pageProps.error} isSSRError />
      </FlashMessageProvider>
    );
  }
  const getLayout = Component.getLayout ?? (page => page);
  return (
    <MsalProvider instance={msalInstance}>
      <MicrosoftAuthProvider>
        <BoxAuthProvider>
          <GoogleAuthProvider>
            <FavoriteCacheProvider>
              <ArticleReadProvider>
                <SubscribingAtMasterPortalProvider>
                  {getLayout(<Component {...pageProps} />, { ...pageProps, t })}
                  {gtmId && <GoogleTagManager gtmId={gtmId} />}
                  {loading && renderLoading()}
                </SubscribingAtMasterPortalProvider>
              </ArticleReadProvider>
            </FavoriteCacheProvider>
          </GoogleAuthProvider>
        </BoxAuthProvider>
      </MicrosoftAuthProvider>
    </MsalProvider>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  