import { SERVICE_NAME } from '~/constants';
import { pvApiClient, Option } from '~/external/api/client';
import { isPWA } from '~/utils/viaService';
import { PostSessionRequest, PostSessionResponse } from './model';

export const SessionService = (option: Option = {}) => {
  const init: RequestInit = {
    headers: {
      'X-PV-API-SERVICE-KEY': SERVICE_NAME,
      'X-PV-API-APPLICATION-TYPE': isPWA() ? 'pwa' : '',
    },
  };
  return {
    PostSession: async (request: PostSessionRequest): Promise<PostSessionResponse> => {
      const response = await pvApiClient(option).post('/v1.0/session', request, init);
      return PostSessionResponse.fromJson(response);
    },
    DeleteSession: async (): Promise<void> => {
      await pvApiClient(option).delete('/v1.0/session', null, init);
      return;
    },
  };
};
