export const ErrorType = {
  ServerError: 'SERVER_ERROR',
  ClientError: 'CLIENT_ERROR',
  NetworkError: 'NETWORK_ERROR',
  Canceled: 'CANCELED',
} as const;

export type ErrorType = (typeof ErrorType)[keyof typeof ErrorType];

export interface RequestErrorPayload {
  endpoint: string;
  method: string;
  body?: unknown;
  errorType: ErrorType;
  status?: number;
  errorMessage?: string;
  innerError?: unknown;
  errorResponseType?: string;
}

export const isHavingRequestErrorPayload = (arg: unknown): arg is RequestErrorPayload => {
  if (typeof arg !== 'object' || arg === null) return false;
  if (
    arg.hasOwnProperty('endpoint') &&
    arg.hasOwnProperty('method') &&
    arg.hasOwnProperty('body') &&
    arg.hasOwnProperty('errorType') &&
    arg.hasOwnProperty('status') &&
    arg.hasOwnProperty('errorMessage') &&
    arg.hasOwnProperty('innerError') &&
    arg.hasOwnProperty('errorResponseType')
  ) {
    return true;
  }

  return false;
};

export const getRequestErrorPayloadObject = <T extends RequestErrorPayload>(arg: T): RequestErrorPayload => {
  return {
    endpoint: arg.endpoint,
    method: arg.method,
    body: arg.body,
    status: arg.status,
    errorMessage: arg.errorMessage,
    errorType: arg.errorType,
    innerError: arg.innerError,
    errorResponseType: arg.errorResponseType,
  };
};

export class RequestError extends Error {
  readonly endpoint: string;
  readonly method: string;
  readonly body?: unknown;
  readonly status?: number;
  readonly errorMessage?: string;
  readonly errorType: ErrorType;
  readonly innerError: unknown;
  readonly errorResponseType?: string;

  constructor({
    endpoint,
    method,
    body,
    errorType,
    status,
    errorMessage,
    innerError,
    errorResponseType,
  }: RequestErrorPayload) {
    // NOTE: bodyは、機微な情報が含まれる可能性があるため、messageに含めない
    super(
      `Request error { endpoint: ${endpoint}, method: ${method}, errorType: ${errorType}, status: ${status}, errorMessage: ${errorMessage}, errorResponseType: ${errorResponseType}, innerError: ${innerError} }`,
    );

    this.endpoint = endpoint;
    this.method = method;
    this.body = body;
    this.innerError = innerError;
    this.errorType = errorType;
    this.status = status;
    this.errorMessage = errorMessage;
    this.errorResponseType = errorResponseType;
  }
}
