import React, { useContext, createContext } from 'react';
import { BoxAuthClient } from './BoxAuthClient';

export interface BoxContextError {
  message: string;
}

type BoxContextType = {
  boxAuthClient?: BoxAuthClient;
};

const BoxContext = createContext<BoxContextType>({
  boxAuthClient: undefined,
});

export const BoxAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <BoxContext.Provider value={{ boxAuthClient: BoxAuthClient.instance ?? undefined }}>{children}</BoxContext.Provider>
  );
};

export const useBoxAuth = () => {
  return useContext(BoxContext);
};
