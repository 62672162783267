/* eslint @typescript-eslint/no-explicit-any: 0 */

import { snakeToCamel } from '~/utils/convertCase';
import { Option } from './option';
import { ErrorType, RequestError } from './RequestError';
import { toRequestInit, parseResponseJson } from './utils';

interface RequestErrorJson {
  error?: {
    message?: string;
    type?: string;
  };
}

const request = async (path: string, init: RequestInit) => {
  const endpoint = `${process.env.NEXT_PUBLIC_API_BASE_URL}${path}`;

  let response: Response;
  try {
    response = await fetch(endpoint, init);
  } catch (error) {
    if (error instanceof RequestError) {
      throw error;
    }
    if (error instanceof Error && error.name === 'AbortError') {
      throw new RequestError({
        endpoint,
        method: init.method ?? 'GET',
        body: init.body,
        errorType: ErrorType.Canceled,
        innerError: error,
      });
    }
    throw new RequestError({
      endpoint,
      method: init.method ?? 'GET',
      body: init.body,
      errorType: ErrorType.NetworkError,
      innerError: error,
    });
  }

  if (!response.ok) {
    const body = await parseResponseJson(response);

    if (response.status >= 400 && response.status < 500) {
      throw new RequestError({
        endpoint,
        method: init.method ?? 'GET',
        body: init.body,
        errorType: ErrorType.ClientError,
        status: response.status,
        errorMessage: (body as RequestErrorJson)?.error?.message,
        errorResponseType: (body as RequestErrorJson)?.error?.type,
      });
    }
    if (response.status >= 500) {
      throw new RequestError({
        endpoint,
        method: init.method ?? 'GET',
        body: init.body,
        errorType: ErrorType.ServerError,
        status: response.status,
        errorMessage: (body as RequestErrorJson)?.error?.message,
        errorResponseType: (body as RequestErrorJson)?.error?.type,
      });
    }
  }
  const json = await parseResponseJson(response);
  return snakeToCamel(json ?? {}) as any;
};

export const client = (option?: Option) => ({
  get: (path: string, init?: RequestInit) => request(path, toRequestInit('GET', init, option)),

  post: (path: string, data: unknown, init?: RequestInit) => request(path, toRequestInit('POST', init, option, data)),

  put: (path: string, data: unknown, init?: RequestInit) => request(path, toRequestInit('PUT', init, option, data)),

  patch: (path: string, data: unknown, init?: RequestInit) => request(path, toRequestInit('PATCH', init, option, data)),

  delete: (path: string, data?: unknown, init?: RequestInit) =>
    request(path, toRequestInit('DELETE', init, option, data)),
});
