import { client, Option } from '~/external/api/client/index';
import { Empty } from '~/external/proto/google/protobuf/empty';
import {
  GetUserInfoResponse,
  PatchUserPasswordRequest,
  UserService as UserServiceType,
} from '~/external/proto/v1/userService';

export const UserService = (option?: Option): UserServiceType => ({
  GetUserInfo: async (): Promise<GetUserInfoResponse> => {
    const response = await client(option).get(`/v1.0/user_info`);
    return GetUserInfoResponse.fromJSON(response);
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PatchUserPassword: async (request: PatchUserPasswordRequest): Promise<Empty> => {
    // const response = await client(option).get(`/v1.0/user_password`);
    // return Empty.fromJSON(response);
    throw new Error('not implemented yet');
  },
});
