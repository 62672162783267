import { PublicClientApplication } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';
import { useMsal } from '@azure/msal-react';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { config } from '~/external/microsoft/config';
import { MsalInstanceEventError } from '~/external/microsoft/error';
import { addActiveAccountChangeHandler, removeActiveAccountChangeHandler } from '~/external/microsoft/msalInstance';

interface MSAContextError {
  message: string;
  debug?: string;
}

type MSAContextType = {
  account: AccountInfo | null;
  error: MSAContextError | null;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  displayError: (message: string, debug?: string) => void;
  clearError: () => void;
};

const MsaContext = createContext<MSAContextType>({
  account: null,
  error: null,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  displayError: () => {},
  clearError: () => {},
});

export const useMicrosoftAuthContext = () => {
  const [account, setAccount] = useState<AccountInfo | null>(null);
  const [error, setError] = useState<MSAContextError | null>(null);
  const msal = useMsal();

  const displayError = useCallback((message: string, debug?: string) => {
    setError({ message, debug });
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  useEffect(() => {
    const handler = (instance: PublicClientApplication) => setAccount(instance.getActiveAccount());
    addActiveAccountChangeHandler(handler, true);
    return () => removeActiveAccountChangeHandler(handler);
  }, []);

  const signIn = useCallback(async () => {
    const nonce = uuid();

    await msal.instance
      .loginPopup({
        scopes: config.scopes,
        prompt: 'select_account',
        redirectUri: `${window.location.origin}/redirect`,
        nonce,
      })
      .catch(error => {
        if (error instanceof MsalInstanceEventError.AcquireTokenFailure) {
          return;
        }
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [msal.instance]);

  const signOut = useCallback(async () => {
    if (!account) {
      return;
    }
    setAccount(null);

    // logout silently
    // @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#requirements-for-front-channel-logout-page
    msal.instance.logoutRedirect({
      onRedirectNavigate: () => false,
    });
  }, [msal, account]);

  const auth = useMemo(
    () => ({
      account,
      error,
      signIn,
      signOut,
      displayError,
      clearError,
    }),
    [clearError, displayError, error, signIn, signOut, account],
  );

  return { auth };
};

export const MicrosoftAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { auth } = useMicrosoftAuthContext();
  return <MsaContext.Provider value={auth}>{children}</MsaContext.Provider>;
};

export const useMicrosoftAuth = () => {
  return useContext(MsaContext);
};
