import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { config } from './config';
import { MsalInstanceEventError } from './error';
import { authStatePersistence } from '../authStatePersistence';

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: config.appId,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    allowRedirectInIframe: true,
  },
});

type ActiveAccountChangeHandler = (instance: PublicClientApplication) => void;
const activeAccountChangeHandlers = new Set<ActiveAccountChangeHandler>();

msalInstance.addEventCallback((event: EventMessage) => {
  if ([EventType.ACQUIRE_TOKEN_FAILURE].includes(event.eventType)) {
    throw new MsalInstanceEventError.AcquireTokenFailure();
  }
  if ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS].includes(event.eventType) && event.payload) {
    const authResult = event.payload as AuthenticationResult;

    if (authResult.account?.localAccountId !== msalInstance.getActiveAccount()?.localAccountId) {
      msalInstance.setActiveAccount(authResult.account);
      activeAccountChangeHandlers.forEach(handler => handler(msalInstance));
    }
    authStatePersistence.microsoft.setAccountSelectionDone(true);
    return;
  }
});

export const addActiveAccountChangeHandler = (handler: ActiveAccountChangeHandler, evaluateImmediately: boolean) => {
  activeAccountChangeHandlers.add(handler);
  if (evaluateImmediately) handler(msalInstance);
};

export const removeActiveAccountChangeHandler = (handler: ActiveAccountChangeHandler) => {
  activeAccountChangeHandlers.delete(handler);
};
