/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import {
  LanguageCode,
  languageCodeFromJSON,
  languageCodeToJSON,
} from "../v1/__languageCodeProtoTypes";
import { Empty } from "../google/protobuf/empty";

export const protobufPackage = "userService";

export interface GetUserInfoResponse {
  user: User | undefined;
}

export interface PatchUserPasswordRequest {
  oldPassword: string;
  newPassword: string;
}

export interface User {
  name: string;
  furigana: string;
  email: string;
  roles: User_PortalRole[];
  id: number;
  authType: User_AuthType;
  hasOrganizationAdmin: boolean;
  identifierHash: string;
  languageCode: LanguageCode;
}

export enum User_Role {
  admin = "admin",
  general = "general",
}

export function user_RoleFromJSON(object: any): User_Role {
  switch (object) {
    case 0:
    case "admin":
      return User_Role.admin;
    case 1:
    case "general":
      return User_Role.general;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum User_Role"
      );
  }
}

export function user_RoleToJSON(object: User_Role): string {
  switch (object) {
    case User_Role.admin:
      return "admin";
    case User_Role.general:
      return "general";
    default:
      return "UNKNOWN";
  }
}

export enum User_AuthType {
  password = "password",
  saml = "saml",
}

export function user_AuthTypeFromJSON(object: any): User_AuthType {
  switch (object) {
    case 0:
    case "password":
      return User_AuthType.password;
    case 1:
    case "saml":
      return User_AuthType.saml;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum User_AuthType"
      );
  }
}

export function user_AuthTypeToJSON(object: User_AuthType): string {
  switch (object) {
    case User_AuthType.password:
      return "password";
    case User_AuthType.saml:
      return "saml";
    default:
      return "UNKNOWN";
  }
}

export interface User_PortalRole {
  portalName: string;
  role: User_Role;
}

function createBaseGetUserInfoResponse(): GetUserInfoResponse {
  return { user: undefined };
}

export const GetUserInfoResponse = {
  fromJSON(object: any): GetUserInfoResponse {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: GetUserInfoResponse): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetUserInfoResponse>, I>>(
    object: I
  ): GetUserInfoResponse {
    const message = createBaseGetUserInfoResponse();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    return message;
  },
};

function createBasePatchUserPasswordRequest(): PatchUserPasswordRequest {
  return { oldPassword: "", newPassword: "" };
}

export const PatchUserPasswordRequest = {
  fromJSON(object: any): PatchUserPasswordRequest {
    return {
      oldPassword: isSet(object.oldPassword) ? String(object.oldPassword) : "",
      newPassword: isSet(object.newPassword) ? String(object.newPassword) : "",
    };
  },

  toJSON(message: PatchUserPasswordRequest): unknown {
    const obj: any = {};
    message.oldPassword !== undefined &&
      (obj.oldPassword = message.oldPassword);
    message.newPassword !== undefined &&
      (obj.newPassword = message.newPassword);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatchUserPasswordRequest>, I>>(
    object: I
  ): PatchUserPasswordRequest {
    const message = createBasePatchUserPasswordRequest();
    message.oldPassword = object.oldPassword ?? "";
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

function createBaseUser(): User {
  return {
    name: "",
    furigana: "",
    email: "",
    roles: [],
    id: 0,
    authType: User_AuthType.password,
    hasOrganizationAdmin: false,
    identifierHash: "",
    languageCode: LanguageCode.code_unspecified,
  };
}

export const User = {
  fromJSON(object: any): User {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      furigana: isSet(object.furigana) ? String(object.furigana) : "",
      email: isSet(object.email) ? String(object.email) : "",
      roles: Array.isArray(object?.roles)
        ? object.roles.map((e: any) => User_PortalRole.fromJSON(e))
        : [],
      id: isSet(object.id) ? Number(object.id) : 0,
      authType: isSet(object.authType)
        ? user_AuthTypeFromJSON(object.authType)
        : User_AuthType.password,
      hasOrganizationAdmin: isSet(object.hasOrganizationAdmin)
        ? Boolean(object.hasOrganizationAdmin)
        : false,
      identifierHash: isSet(object.identifierHash)
        ? String(object.identifierHash)
        : "",
      languageCode: isSet(object.languageCode)
        ? languageCodeFromJSON(object.languageCode)
        : LanguageCode.code_unspecified,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.furigana !== undefined && (obj.furigana = message.furigana);
    message.email !== undefined && (obj.email = message.email);
    if (message.roles) {
      obj.roles = message.roles.map((e) =>
        e ? User_PortalRole.toJSON(e) : undefined
      );
    } else {
      obj.roles = [];
    }
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.authType !== undefined &&
      (obj.authType = user_AuthTypeToJSON(message.authType));
    message.hasOrganizationAdmin !== undefined &&
      (obj.hasOrganizationAdmin = message.hasOrganizationAdmin);
    message.identifierHash !== undefined &&
      (obj.identifierHash = message.identifierHash);
    message.languageCode !== undefined &&
      (obj.languageCode = languageCodeToJSON(message.languageCode));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.name = object.name ?? "";
    message.furigana = object.furigana ?? "";
    message.email = object.email ?? "";
    message.roles =
      object.roles?.map((e) => User_PortalRole.fromPartial(e)) || [];
    message.id = object.id ?? 0;
    message.authType = object.authType ?? User_AuthType.password;
    message.hasOrganizationAdmin = object.hasOrganizationAdmin ?? false;
    message.identifierHash = object.identifierHash ?? "";
    message.languageCode = object.languageCode ?? LanguageCode.code_unspecified;
    return message;
  },
};

function createBaseUser_PortalRole(): User_PortalRole {
  return { portalName: "", role: User_Role.admin };
}

export const User_PortalRole = {
  fromJSON(object: any): User_PortalRole {
    return {
      portalName: isSet(object.portalName) ? String(object.portalName) : "",
      role: isSet(object.role)
        ? user_RoleFromJSON(object.role)
        : User_Role.admin,
    };
  },

  toJSON(message: User_PortalRole): unknown {
    const obj: any = {};
    message.portalName !== undefined && (obj.portalName = message.portalName);
    message.role !== undefined && (obj.role = user_RoleToJSON(message.role));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<User_PortalRole>, I>>(
    object: I
  ): User_PortalRole {
    const message = createBaseUser_PortalRole();
    message.portalName = object.portalName ?? "";
    message.role = object.role ?? User_Role.admin;
    return message;
  },
};

export interface UserService {
  GetUserInfo(request: Empty): Promise<GetUserInfoResponse>;
  PatchUserPassword(request: PatchUserPasswordRequest): Promise<Empty>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
