import { parseCookies, setCookie } from 'nookies';
import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { RequestError } from '~/external/api/client/RequestError';
import { UserService } from '~/external/api/userService';

// https://swr.vercel.app/ja/docs/mutation

const KEY = `/user-info`;

export const useMutate = () => {
  const { mutate: mutate_ } = useSWRConfig();
  const mutate = useCallback(() => mutate_(KEY), [mutate_]);

  /** ログイン・ログアウトしたときに呼ぶ */
  return { mutate };
};

export const useUserInfo = () => {
  const { data, isValidating } = useSWR(
    KEY,
    async () => {
      try {
        const { user } = await UserService().GetUserInfo({});
        const languageCode = parseCookies().PORTAL_LOCALE;

        if (user && user.languageCode !== languageCode) {
          setCookie(null, 'PORTAL_LOCALE', user.languageCode, {
            maxAge: 30 * 24 * 60 * 60, // 30日間の有効期限
            path: '/',
          });
          window.location.reload();
        }
        return user;
      } catch (e) {
        // https://swr.vercel.app/ja/docs/error-handling#%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9%E3%82%B3%E3%83%BC%E3%83%89%E3%81%A8%E3%82%A8%E3%83%A9%E3%83%BC%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88
        // エラーが発生してもuseSWRは既存データを返すため、401の場合はundefinedを返す
        if (e instanceof RequestError && e.status === 401) {
          return undefined;
        }
        throw e;
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return { user: data ?? null, isValidating };
};
