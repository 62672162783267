import { camelToSnake } from '~/utils/convertCase';
import { Option } from './option';

const SESSION_KEY = ['_pv_api_session', '_dev_video_b', '_stg_video_b', '_prod_video_b'];

export const toRecord = (headers?: HeadersInit): Record<string, string> => {
  if (!headers) return {};
  if (Array.isArray(headers)) return headers.reduce((prev, current) => ({ ...prev, [current[0]]: current[1] }), {});
  if (headers instanceof Headers) {
    const record: Record<string, string> = {};
    for (const [key, value] of headers.entries()) {
      record[key] = value;
    }
    return record;
  }
  return headers;
};

const parseCookie = (str = '') =>
  str
    .split(';')
    .map(v => v.trim().split('='))
    .reduce(
      (prev, current) =>
        current.length === 2
          ? {
              ...prev,
              [decodeURIComponent(current[0].trim())]: decodeURIComponent(current[1].trim()),
            }
          : prev,
      {},
    );

export const toRequestInit = (
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  init?: RequestInit,
  option?: Option,
  data?: unknown,
): RequestInit => {
  const headers: Record<string, string> = {
    ...toRecord(init?.headers),
  };

  if (method !== 'GET' && data && !(data instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }
  if (option?.cookies) {
    const initCookie = headers.Cookie || headers.cookie;
    const mergedCookie: Record<string, string> = {
      ...option.cookies,
      ...parseCookie(initCookie),
    };
    SESSION_KEY.forEach(key => {
      if (mergedCookie[key]) {
        mergedCookie[key] = encodeURIComponent(mergedCookie[key]);
      }
    });
    headers['Cookie'] = Object.entries(mergedCookie)
      .map(([key, value]) => `${key}=${value}`)
      .join('; ');
  }
  const requestInit: RequestInit = {
    ...init,
    method,
    headers,
    credentials: init?.credentials || 'include',
  };

  if (data) {
    switch (headers['Content-Type']) {
      case 'application/json': {
        requestInit.body = JSON.stringify(camelToSnake(data));
        break;
      }
      default: {
        requestInit.body = data as BodyInit;
        break;
      }
    }
  }

  return requestInit;
};

export const parseResponseJson = async (response: Response) => {
  const body = await response.text();

  if (!body) {
    return {};
  }
  return JSON.parse(body);
};
