import { createContext, useCallback, useContext, useState } from 'react';
import { GoogleAuthClient } from './GoogleAuthClient';

interface GoogleContextType {
  getAccountSelectionDone: () => boolean;
  switchAccount: () => void;
  renderGoogleLoader: () => JSX.Element;
  isGoogleAuthAvailable: boolean;
}

const GoogleContext = createContext<GoogleContextType>({
  getAccountSelectionDone: () => false,
  switchAccount: () => null,
  renderGoogleLoader: () => <></>,
  isGoogleAuthAvailable: false,
});

const useGoogleAuthClient = () => {
  const [gsiLoaded, setGsiLoaded] = useState(false);
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
  const googleAuthClient = GoogleAuthClient.instance ?? undefined;
  const onLoadApi = () => {
    setPickerApiLoaded(true);
  };
  const onLoadGsi = () => {
    setGsiLoaded(true);
  };

  const renderGoogleLoader = useCallback(
    () => (
      <>
        {googleAuthClient?.renderApiLoader(onLoadApi)}
        {googleAuthClient?.renderGsiLoader(onLoadGsi)}
      </>
    ),
    [googleAuthClient],
  );

  const switchAccount = useCallback(() => {
    googleAuthClient?.signIn();
  }, [googleAuthClient]);

  const getAccountSelectionDone = useCallback(() => {
    return googleAuthClient?.getAccountSelectionDone() ?? false;
  }, [googleAuthClient]);

  return {
    getAccountSelectionDone,
    switchAccount,
    renderGoogleLoader,
    isGoogleAuthAvailable: gsiLoaded && pickerApiLoaded,
  };
};

export const GoogleAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getAccountSelectionDone, switchAccount, renderGoogleLoader, isGoogleAuthAvailable } = useGoogleAuthClient();

  return (
    <GoogleContext.Provider
      value={{ getAccountSelectionDone, switchAccount, renderGoogleLoader, isGoogleAuthAvailable }}
    >
      {children}
    </GoogleContext.Provider>
  );
};

export const useGoogleAuth = () => {
  return useContext(GoogleContext);
};
