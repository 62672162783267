import React from 'react';
interface Props {
  width?: number;
  height?: number;
}

export const Loading: React.FC<Props> = ({ width = 30, height = 30 }) => (
  <div
    style={{ width: `${width}px`, height: `${height}px` }}
    className="rounded-[50%] border-[4px] border-[#DADADA] border-t-black-500 border-solid animate-spin"
  />
);
