/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "languageCodeProtoTypes";

export enum LanguageCode {
  code_unspecified = "code_unspecified",
  ja = "ja",
  en = "en",
  zh = "zh",
  zh_hk = "zh_hk",
  id = "id",
  th = "th",
  ne = "ne",
  hi = "hi",
  fil = "fil",
  vi = "vi",
  pt = "pt",
  ms = "ms",
  my = "my",
  ko = "ko",
  is = "is",
  af = "af",
  it = "it",
  uk = "uk",
  nl = "nl",
  ca = "ca",
  gl = "gl",
  kn = "kn",
  el = "el",
  gu = "gu",
  sv = "sv",
  es_es = "es_es",
  sk = "sk",
  sr = "sr",
  ta = "ta",
  cs = "cs",
  te = "te",
  da = "da",
  de = "de",
  tr = "tr",
  no = "no",
  eu = "eu",
  hu = "hu",
  pa = "pa",
  fi = "fi",
  fr = "fr",
  bg = "bg",
  bn = "bn",
  pl = "pl",
  mr = "mr",
  ml = "ml",
  lv = "lv",
  lt = "lt",
  ro = "ro",
  ru = "ru",
}

export function languageCodeFromJSON(object: any): LanguageCode {
  switch (object) {
    case 0:
    case "code_unspecified":
      return LanguageCode.code_unspecified;
    case 1:
    case "ja":
      return LanguageCode.ja;
    case 2:
    case "en":
      return LanguageCode.en;
    case 3:
    case "zh":
      return LanguageCode.zh;
    case 4:
    case "zh_hk":
      return LanguageCode.zh_hk;
    case 5:
    case "id":
      return LanguageCode.id;
    case 6:
    case "th":
      return LanguageCode.th;
    case 7:
    case "ne":
      return LanguageCode.ne;
    case 8:
    case "hi":
      return LanguageCode.hi;
    case 9:
    case "fil":
      return LanguageCode.fil;
    case 10:
    case "vi":
      return LanguageCode.vi;
    case 11:
    case "pt":
      return LanguageCode.pt;
    case 12:
    case "ms":
      return LanguageCode.ms;
    case 13:
    case "my":
      return LanguageCode.my;
    case 14:
    case "ko":
      return LanguageCode.ko;
    case 15:
    case "is":
      return LanguageCode.is;
    case 16:
    case "af":
      return LanguageCode.af;
    case 17:
    case "it":
      return LanguageCode.it;
    case 18:
    case "uk":
      return LanguageCode.uk;
    case 19:
    case "nl":
      return LanguageCode.nl;
    case 20:
    case "ca":
      return LanguageCode.ca;
    case 21:
    case "gl":
      return LanguageCode.gl;
    case 22:
    case "kn":
      return LanguageCode.kn;
    case 23:
    case "el":
      return LanguageCode.el;
    case 24:
    case "gu":
      return LanguageCode.gu;
    case 25:
    case "sv":
      return LanguageCode.sv;
    case 26:
    case "es_es":
      return LanguageCode.es_es;
    case 27:
    case "sk":
      return LanguageCode.sk;
    case 28:
    case "sr":
      return LanguageCode.sr;
    case 29:
    case "ta":
      return LanguageCode.ta;
    case 30:
    case "cs":
      return LanguageCode.cs;
    case 31:
    case "te":
      return LanguageCode.te;
    case 32:
    case "da":
      return LanguageCode.da;
    case 33:
    case "de":
      return LanguageCode.de;
    case 34:
    case "tr":
      return LanguageCode.tr;
    case 35:
    case "no":
      return LanguageCode.no;
    case 36:
    case "eu":
      return LanguageCode.eu;
    case 37:
    case "hu":
      return LanguageCode.hu;
    case 38:
    case "pa":
      return LanguageCode.pa;
    case 39:
    case "fi":
      return LanguageCode.fi;
    case 40:
    case "fr":
      return LanguageCode.fr;
    case 41:
    case "bg":
      return LanguageCode.bg;
    case 42:
    case "bn":
      return LanguageCode.bn;
    case 43:
    case "pl":
      return LanguageCode.pl;
    case 44:
    case "mr":
      return LanguageCode.mr;
    case 45:
    case "ml":
      return LanguageCode.ml;
    case 46:
    case "lv":
      return LanguageCode.lv;
    case 47:
    case "lt":
      return LanguageCode.lt;
    case 48:
    case "ro":
      return LanguageCode.ro;
    case 49:
    case "ru":
      return LanguageCode.ru;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum LanguageCode"
      );
  }
}

export function languageCodeToJSON(object: LanguageCode): string {
  switch (object) {
    case LanguageCode.code_unspecified:
      return "code_unspecified";
    case LanguageCode.ja:
      return "ja";
    case LanguageCode.en:
      return "en";
    case LanguageCode.zh:
      return "zh";
    case LanguageCode.zh_hk:
      return "zh_hk";
    case LanguageCode.id:
      return "id";
    case LanguageCode.th:
      return "th";
    case LanguageCode.ne:
      return "ne";
    case LanguageCode.hi:
      return "hi";
    case LanguageCode.fil:
      return "fil";
    case LanguageCode.vi:
      return "vi";
    case LanguageCode.pt:
      return "pt";
    case LanguageCode.ms:
      return "ms";
    case LanguageCode.my:
      return "my";
    case LanguageCode.ko:
      return "ko";
    case LanguageCode.is:
      return "is";
    case LanguageCode.af:
      return "af";
    case LanguageCode.it:
      return "it";
    case LanguageCode.uk:
      return "uk";
    case LanguageCode.nl:
      return "nl";
    case LanguageCode.ca:
      return "ca";
    case LanguageCode.gl:
      return "gl";
    case LanguageCode.kn:
      return "kn";
    case LanguageCode.el:
      return "el";
    case LanguageCode.gu:
      return "gu";
    case LanguageCode.sv:
      return "sv";
    case LanguageCode.es_es:
      return "es_es";
    case LanguageCode.sk:
      return "sk";
    case LanguageCode.sr:
      return "sr";
    case LanguageCode.ta:
      return "ta";
    case LanguageCode.cs:
      return "cs";
    case LanguageCode.te:
      return "te";
    case LanguageCode.da:
      return "da";
    case LanguageCode.de:
      return "de";
    case LanguageCode.tr:
      return "tr";
    case LanguageCode.no:
      return "no";
    case LanguageCode.eu:
      return "eu";
    case LanguageCode.hu:
      return "hu";
    case LanguageCode.pa:
      return "pa";
    case LanguageCode.fi:
      return "fi";
    case LanguageCode.fr:
      return "fr";
    case LanguageCode.bg:
      return "bg";
    case LanguageCode.bn:
      return "bn";
    case LanguageCode.pl:
      return "pl";
    case LanguageCode.mr:
      return "mr";
    case LanguageCode.ml:
      return "ml";
    case LanguageCode.lv:
      return "lv";
    case LanguageCode.lt:
      return "lt";
    case LanguageCode.ro:
      return "ro";
    case LanguageCode.ru:
      return "ru";
    default:
      return "UNKNOWN";
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
