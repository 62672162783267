import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { MessageLevel, useFlashMessage } from '../FlashMessageProvider';

export const FlashMessage: React.FC = () => {
  const [isShown, setIsShown] = useState<boolean>();
  const { flashMessage } = useFlashMessage();

  useEffect(() => {
    if (flashMessage.message) {
      setIsShown(true);
      setTimeout(() => {
        setIsShown(false);
      }, 3000);
    }
  }, [flashMessage]);

  return (
    <p
      className={clsx([
        'fixed top-6 left-1/2 z-[10000000] -translate-x-1/2 sm:top-[70px]',
        'py-2 px-4 rounded-full',
        'text-center text-white whitespace-pre-wrap title-14',
        'transition-opacity duration-300 ease-in-out',
        !isShown && 'opacity-0',
        isShown ? 'pointer-events-auto' : 'pointer-events-none',
        flashMessage.level === MessageLevel.success ? 'bg-accent-500' : 'bg-red-900',
      ])}
    >
      {flashMessage.message}
    </p>
  );
};
