export interface InitCodeClientResponse {
  code: string;
  scope: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInitCodeClientResponse = (o: any): o is InitCodeClientResponse => {
  if (!o) return false;
  if (typeof o !== 'object') return false;
  return typeof o.code === 'string' && typeof o.scope === 'string';
};

export interface InitCodeClientError {
  error: string;
  state: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInitCodeClientError = (o: any): o is InitCodeClientError => {
  if (!o) return false;
  if (typeof o !== 'object') return false;
  return typeof o.error === 'string' && typeof o.state === 'string';
};

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTokenResponse = (o: any): o is TokenResponse => {
  if (!o) return false;
  if (typeof o !== 'object') return false;
  return (
    typeof o.access_token === 'string' &&
    typeof o.expires_in === 'number' &&
    typeof o.refresh_token === 'string' &&
    typeof o.scope === 'string' &&
    typeof o.token_type === 'string'
  );
};

export interface RefreshTokenResponse {
  access_token: string;
  expires_in: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRefreshTokenResponse = (o: any): o is RefreshTokenResponse => {
  if (!o) return false;
  if (typeof o !== 'object') return false;
  return typeof o.access_token === 'string' && typeof o.expires_in === 'number';
};

export interface AuthInfo {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAuthInfo = (o: any): o is AuthInfo => {
  if (!o) return false;
  return typeof o.accessToken === 'string' && typeof o.refreshToken === 'string' && typeof o.expiresAt === 'number';
};
