import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';

interface SubscribingAtMasterPortalContextType {
  changeIsSubscribingAtMasterPortal: (changed_subscribing: boolean) => void;
  isSubscribingAtMasterPortal: boolean;
}

const SubscribingAtMasterPortalContext = createContext<SubscribingAtMasterPortalContextType>({
  changeIsSubscribingAtMasterPortal: () => {},
  isSubscribingAtMasterPortal: false,
});

export const SubscribingAtMasterPortalProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSubscribingAtMasterPortal, setIsSubscribingAtMasterPortal] = useState<boolean>(false);

  const changeIsSubscribingAtMasterPortal = useCallback((subscribing: boolean) => {
    setIsSubscribingAtMasterPortal(subscribing);
  }, []);

  const value = useMemo(
    () => ({ changeIsSubscribingAtMasterPortal, isSubscribingAtMasterPortal }),
    [changeIsSubscribingAtMasterPortal, isSubscribingAtMasterPortal],
  );

  return (
    <SubscribingAtMasterPortalContext.Provider value={value}>{children}</SubscribingAtMasterPortalContext.Provider>
  );
};

export const useSubscribingAtMasterPortal = () => {
  return useContext(SubscribingAtMasterPortalContext);
};
