import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { useFlashMessage } from '~/components/functional/FlashMessageProvider';
import { useMicrosoftAuth } from '~/components/functional/MicrosoftAuthProvider';
import { RequestError } from '~/external/api/client/RequestError';
import { authStatePersistence } from '~/external/authStatePersistence';
import { SessionService } from '~/external/pvApi/session';
import { useLogin } from '~/hooks/domain/session/useLogin';
import { useMutate } from '~/hooks/domain/user/useUserInfo';

export const useLogout = () => {
  const { mutate } = useMutate();
  const { sendFlashMessage } = useFlashMessage();
  const { t } = useTranslation();
  const { signOut } = useMicrosoftAuth();
  const { redirectToLogin } = useLogin();

  const logout = useCallback(async () => {
    try {
      await SessionService().DeleteSession();
      await authStatePersistence.removeAll({ microsoftSignOut: signOut });

      await redirectToLogin();
      mutate();
    } catch (error) {
      if (error instanceof RequestError && (error.status === 401 || error.status === 405)) {
        // 未ログインとセッションアウト時はログアウト処理を継続
        await authStatePersistence.removeAll({ microsoftSignOut: signOut });
        await redirectToLogin();
        mutate();
      } else {
        sendFlashMessage({ message: t('common:message.logoutFail'), level: 'error', usedInAdmin: true });
      }
    }
  }, [signOut, redirectToLogin, mutate, sendFlashMessage, t]);

  return {
    logout,
  };
};
