import { client, Option } from '~/external/api/client';
import {
  GetOrganizationMovieUsageRequest,
  GetOrganizationMovieUsageResponse,
  GetOrganizationPlanPropertiesRequest,
  GetOrganizationPlanPropertiesResponse,
  GetOrganizationPortalUsageRequest,
  GetOrganizationPortalUsageResponse,
  GetOrganizationResponse,
  OrganizationService as OrganizationServiceType,
} from '~/external/proto/v1/organizationService';

export const OrganizationService = (option?: Option): OrganizationServiceType => ({
  GetOrganization: async (): Promise<GetOrganizationResponse> => {
    const response = await client(option).get(`/v1.0/organization`);
    return GetOrganizationResponse.fromJSON(response);
  },
  GetOrganizationMovieUsage: async (
    request: GetOrganizationMovieUsageRequest,
  ): Promise<GetOrganizationMovieUsageResponse> => {
    const response = await client(option).get(`/v1.0/organizations/${request.organizationName}/movie_usage`);
    return GetOrganizationMovieUsageResponse.fromJSON(response);
  },
  GetOrganizationPlanProperties: async (request: GetOrganizationPlanPropertiesRequest) => {
    const response = await client(option).get(`/v1.0/organizations/${request.organizationName}/plan_properties`);
    return GetOrganizationPlanPropertiesResponse.fromJSON(response);
  },
  GetOrganizationPortalUsage: async (
    request: GetOrganizationPortalUsageRequest,
  ): Promise<GetOrganizationPortalUsageResponse> => {
    const response = await client(option).get(`/v1.0/organizations/${request.organizationName}/portal_usage`);
    return GetOrganizationPortalUsageResponse.fromJSON(response);
  },
});
