// レスポンスの内使うもののみ抜粋
export interface BoxAuthResponse {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}

export const BoxAuthResponse = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fromJson: (object: any): BoxAuthResponse => {
    return {
      accessToken: String(object.accessToken ?? ''),
      expiresIn: Number(object.expiresIn ?? 0),
      refreshToken: String(object.refreshToken ?? ''),
    };
  },
};

/** localStorageに保存されているBoxの認証情報の型 */
export interface BoxAuthInfo {
  accessToken: string;
  expiresAt: number;
  refreshToken: string;
}

export const BoxAuthInfo = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fromJson: (object: any): BoxAuthInfo => {
    return {
      accessToken: String(object.accessToken ?? ''),
      expiresAt: Number(object.expiresAt ?? 0),
      refreshToken: String(object.refreshToken ?? ''),
    };
  },
};
